<template>
  <div v-if="lastPage > 1" class="pagination">
    <md-button
      class="md-primary"
      :disabled="currentPage <= 1"
      @click="$emit('change-page', currentPage - 1)"
    >
      <md-icon>navigate_before</md-icon>
    </md-button>
    <div class="pages">
      <span>{{ currentPage }} / {{ lastPage }}</span>
    </div>
    <md-button
      :disabled="currentPage === lastPage"
      class="md-primary"
      @click="$emit('change-page', currentPage + 1)"
    >
      <md-icon>navigate_next</md-icon>
    </md-button>
    <div v-if="withSearch" class="page-search">
      <md-field class="md-layout-item">
        <md-input v-model="searchPage" />
      </md-field>
      <md-button class="md-raised md-primary search-btn" @click="search">
        Показать страницу
      </md-button>
    </div>
    <div class="spin">
      <md-progress-spinner
        v-if="showSpinner"
        class="md-green"
        :md-diameter="15"
        :md-stroke="3"
        md-mode="indeterminate"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    withSearch: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      searchPage: "",
    };
  },

  computed: {
    ...mapState({
      showSpinner: (state) => state.showSpinner,
    }),
  },

  methods: {
    search() {
      const page =
        this.searchPage <= this.lastPage ? this.searchPage : this.lastPage;
      this.searchPage = page < 1 ? 1 : page;
      this.$emit("change-page", this.searchPage);
    },
  },
};
</script>

<style scoped>
.spin {
  position: absolute;
  right: 0;
}
.pages {
  margin: 0 20px;
}
.page-search {
  position: absolute;
  display: flex;
  right: 25px;
  align-items: center;
}
</style>
